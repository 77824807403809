const namedKeys = {
 allUp: '@media (min-width: 320px)',
 desktopAndUp: '@media (min-width: 1200px)',
 tabletLandscapeAndUp: '@media (min-width: 900px)',
 tabletPortraitAndUp: '@media (min-width: 600px)',
 phoneOnly: '@media (max-width: 599px)',
 betweenMinAndMax: '@media (min-width: 320px) and (max-width: 1200px)',
 maxAndAbove: '@media (min-width: 1199px)',
 ipadMiniAndUp: '@media (min-width: 768px)',
 ipadMiniAndDown: '@media (max-width: 768px)'
};

export default namedKeys;
